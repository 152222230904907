<tb-top-nav page-name="{{ strings['settings'] }}"></tb-top-nav>

<div class="settings">
  <div class="half-page">
    <tb-user-settings></tb-user-settings>
    <tb-user-admin></tb-user-admin>
  </div>

  <div class="half-page">
    <tb-board-admin></tb-board-admin>
    <tb-auto-actions></tb-auto-actions>
  </div>
</div>

