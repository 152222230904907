<section>
  <h2>{{ strings['settings_automaticActions'] }}</h2>

  <div class="row">
    <h3>{{ strings['settings_currentAction'] }}</h3>

    <table class="alternating no-bottom-margin">
      <thead>
        <tr>
          <th>{{ strings['settings_board'] }}</th>
          <th>{{ strings['settings_trigger'] }}</th>
          <th>{{ strings['settings_action'] }}</th>
          <th>{{ strings['settings_remove'] }}</th>
        </tr>
      </thead>

      <tbody *ngIf="!loading">
        <tr *ngIf="autoActions && autoActions.length === 0">
          <td colspan="4" class="center"
            [innerHTML]="noActionsMessage"></td>
        </tr>
        <tr *ngFor="let action of autoActions">
          <td>{{ getBoardName(action.board_id) }}</td>
          <td>{{ getTriggerDescription(action) }}</td>
          <td [innerHTML]="getTypeDescription(action)"></td>
          <td>
            <span *ngIf="activeUser && activeUser.security_level < 3">
              <a href="javascript:"
                title="{{ strings['settings_removeAutoAction'] }}"
                (click)="showConfirmModal(action)">
                <i class="icon icon-trash-empty
                  color-secondary"></i>
              </a>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="!loading && hasInactiveBoards">
      <em>* {{ strings['settings_inactiveBoardMessage'] }}</em>
    </div>

    <div *ngIf="loading" class="center">
      <div class="spinner"></div>
    </div>
  </div>

  <div class="row" *ngIf="activeUser && activeUser.security_level < 3">
    <h3>{{ strings['settings_addAction'] }}</h3>

    <table>
      <thead>
        <tr class="borderless">
          <th>{{ strings['settings_selectBoard'] }}:</th>
          <th>{{ strings['settings_selectTrigger'] }}:</th>
          <th>{{ strings['settings_selectAction'] }}</th>
        </tr>
      </thead>

      <tbody>
        <tr class="borderless">
          <td>
            <select [(ngModel)]="newAction.board_id"
              (change)="updateTriggerSources()">
              <option [ngValue]="null">
                {{ strings['settings_selectBoard'] }}...
              </option>
              <option *ngFor="let board of boards"
                [ngValue]="board.id">
                {{ board.name }}
              </option>
            </select>
          </td>
          <td>
            <select [disabled]="newAction.board_id === null"
              [(ngModel)]="newAction.trigger"
              (change)="updateTriggerSources()">
              <option *ngFor="let trigger of triggers"
                [ngValue]="trigger[0]">{{ trigger[1] }}</option>
            </select>
          </td>
          <td>
            <select [disabled]="newAction.board_id === null"
              [(ngModel)]="newAction.type"
              (change)="updateActionSources()">
              <option *ngFor="let type of types"
                [ngValue]="type[0]">{{ type[1] }}</option>
            </select>
          </td>
        </tr>

        <tr class="borderless">
          <td></td>
          <td>
            <select [disabled]="newAction.board_id === null"
              *ngIf="triggerSources.length"
              [(ngModel)]="newAction.source_id"
              (change)="checkAddDisabled()">
              <option *ngFor="let source of triggerSources"
                [ngValue]="source[0]">{{ source[1] }}</option>
            </select>
          </td>
          <td>
            <select [disabled]="newAction.board_id === null"
              [(ngModel)]="newAction.change_to"
              (change)="checkAddDisabled()"
              *ngIf="newAction.type > 1 && newAction.type < 6">
              <option *ngFor="let source of actionSources"
                [ngValue]="source[0]">{{ source[1] }}</option>
            </select>
            <input type="color" name="change-to-color"
            [disabled]="newAction.board_id === null"
            *ngIf="newAction.type == 1"
            [(ngModel)]="newAction.change_to"
            (change)="checkAddDisabled()">
          </td>
        </tr>
      </tbody>
    </table>

    <button class="right"
      [disabled]="isAddDisabled"
      (click)="addNewAction()">
      <i class="icon icon-plus"></i>
      {{ strings['settings_addAction'] }}
    </button>
  </div>
</section>

<tb-modal modal-title="Confirm Automatic Action Removal" blocking="true"
  modal-id="{{ MODAL_CONFIRM_ID }}">

  <div class="center">
    Removing an automatic action cannot be undone.<br>Continue?
  </div>

  <div class="buttons">
    <button class="flat"
      (click)="removeAutoAction()"
      [disabled]="saving">Yes</button>
    <button #defaultAction #focusMe
      (click)="modal.close(MODAL_CONFIRM_ID)"
      [disabled]="saving">No</button>
  </div>
</tb-modal>

