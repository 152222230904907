<section>
  <h2>{{ strings['settings_userSettings'] }}</h2>

  <div class="half">
    <h3>{{ strings['settings_changePassword'] }}</h3>

    <form>
      <label for="currentPassword" class="hidden">
        {{ strings['settings_currentPassword'] }}</label>
      <input type="password" id="currentPassword" name="currentPassword"
      placeholder="{{ strings['settings_currentPassword'] }}"
      [(ngModel)]="changePassword.current">

      <label for="newPassword" class="hidden">
        {{ strings['settings_newPassword'] }}</label>
      <input type="password" id="newPassword" name="newPassword"
      placeholder="{{ strings['settings_newPassword'] }}"
      [(ngModel)]="changePassword.newPass">

      <label for="verifyPassword" class="hidden">
        {{ strings['settings_verifyPassword'] }}</label>
      <input type="password" id="verifyPassword" name="verifyPassword"
      placeholder="{{ strings['settings_verifyPassword'] }}"
      [(ngModel)]="changePassword.verPass">

      <button type="submit" [disabled]="changePassword.submitted"
        (click)="updatePassword()">{{ strings['settings_changePassword'] }}</button>
      <button class="flat" [disabled] = "changePassword.submitted"
        (click)="resetPasswordForm()">{{ strings['reset'] }}</button>
    </form>

    <h3 class="tall">{{ strings['settings_globalOptions'] }}</h3>
    <label>
      {{ strings['settings_displayLanguage'] }}:
      <select class="autosize"
        *ngIf="userOptions"
        [ngModel]="userOptions.language"
        (ngModelChange)="onOptionChange('language', $event)">
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
        <option value="de">Deutsch</option>
      </select>&nbsp;
      <a href="https://github.com/kiswa/TaskBoard/wiki/Translations"
        target="translations" rel="noopener noreferrer">Add Yours?</a>
    </label>
    <label>
      {{ strings['settings_selectDefaultBoard'] }}:
      <select class="autosize"
        *ngIf="user"
        [ngModel]="user.default_board_id"
        (ngModelChange)="updateDefaultBoard($event)">
        <option value="0">{{ strings['none'] }}</option>
        <option *ngFor="let board of boards" value="{{ board.id }}">
          {{ board.name }}
        </option>
      </select>
    </label>
    <label>
      {{ strings['settings_newTasks'] }}:
      <select class="autosize"
        *ngIf="userOptions"
        [ngModel]="userOptions.new_tasks_at_bottom"
        (ngModelChange)="onOptionChange('new_tasks', $event)">
        <option value="true">{{ strings['settings_bottom'] }}</option>
        <option value="false">{{ strings['settings_top'] }}</option>
      </select>
    </label>
  </div>

  <div class="half">
    <h3>{{ strings['settings_changeUsername'] }}</h3>

    <form>
      <label for="username" class="hidden">{{ strings['settings_newUsername'] }}</label>
      <input type="text" id="username" name="username"
      placeholder="{{ strings['settings_newUsername'] }}"
      [(ngModel)]="changeUsername.newName">

      <button type="submit" [disabled]="changeUsername.submitted"
        (click)="updateUsername()">{{ strings['settings_changeUsername'] }}</button>
      <button class="flat" [disabled]="changeUsername.submitted"
        (click)="resetUsernameForm()">{{ strings['reset'] }}</button>
    </form>

    <form>
      <h3 class="tall">{{ strings['settings_changeEmail'] }}</h3>

      <label for="email" class="hidden">{{ strings['settings_newEmail'] }}</label>
      <input type="text" id="email" name="email"
      placeholder="{{ strings['settings_newEmail'] }} - {{ strings['settings_blank'] }}"
      [(ngModel)]="changeEmail.newEmail">

      <button type="submit" [disabled]="changeEmail.submitted"
        (click)="updateEmail()">{{ strings['settings_changeEmail'] }}</button>
      <button class="flat" [disabled]="changeEmail.submitted"
        (click)="resetEmailForm()">{{ strings['reset'] }}</button>
    </form>

    <div class="hold-bottom" *ngIf="userOptions">
      <label>
        {{ strings['settings_optionsDisplay'] }}
        <input type="checkbox" class="hidden"
        [ngModel]="userOptions.multiple_tasks_per_row"
        (ngModelChange)="onOptionChange('mult_tasks', $event)">
        <span class="toggle"></span>
      </label>
      <label>
        {{ strings['settings_optionsAnimate'] }}
        <input type="checkbox" class="hidden"
        [ngModel]="userOptions.show_animations"
        (ngModelChange)="onOptionChange('show_anim', $event)">
        <span class="toggle"></span>
      </label>
      <label>
        {{ strings['settings_optionsAssignee'] }}
        <input type="checkbox" class="hidden"
        [ngModel]="userOptions.show_assignee"
        (ngModelChange)="onOptionChange('show_assign', $event)">
        <span class="toggle"></span>
      </label>
    </div>
  </div>
</section>

