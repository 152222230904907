import { Component } from '@angular/core';

@Component({
  selector: 'tb-app-component',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor() { }
}

