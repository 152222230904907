<nav class="nav-top">
  <h1>
    TaskBoard
    <span>
      <small>
        - {{ pageName }}
        <small class="dark small">(v{{ version }})</small>
      </small>
    </span>
  </h1>

  <div class="buttons" *ngIf="showButtons">
    <button [ngClass]="{ flat: !isActive('dashboard') }"
      (click)="navigateTo('dashboard')">{{ strings['dashboard'] }}</button>

    <button [ngClass]="{ flat: !isActive('boards') }"
      (click)="navigateTo('boards')">{{ strings['boards'] }}</button>

    <button [ngClass]="{ flat: !isActive('settings') }"
      (click)="navigateTo('settings')">{{ strings['settings'] }}</button>

    <button class="flat"
      (click)="logout()">{{ strings['logout'] }} ({{ username }})</button>
  </div>
</nav>

