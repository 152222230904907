<div class="inline-edit">
  <span [hidden]="!isDisplay">
    {{ text }}
    <i class="icon icon-edit color-primary" title="Edit"
      (click)="beginEdit(editText)"></i>
  </span>
  <span [hidden]="isDisplay">
    <input #editText type="text"
    [value]="text" (keyup.enter)="editDone(editText.value, $event)">
    <i class="icon icon-floppy color-primary" title="Save"
      (click)="editDone(editText.value)"></i>
  </span>
</div>

